globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"epTrzWMshT77jCJDltk7B"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs"
import isProduction from "@secureo/common/utils/isProduction"
import { sharedClientOptions } from "@secureo/common/config/sentry.config"

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN
if (isProduction())
	Sentry.init({
		...sharedClientOptions,
		dsn:
			SENTRY_DSN ||
			"https://199b3765eb604ac1a69a7a0f8f58f4c7@o232044.ingest.sentry.io/5278639",
		// Adjust this value in production, or use tracesSampler for greater control
		tracesSampleRate: 1.0,
		// ...
		// Note: if you want to override the automatic release value, do not set a
		// `release` value here - use the environment variable `SENTRY_RELEASE`, so
		// that it will also get attached to your source maps
	})
